import EmptyPage from '@/components/common/misc/EmptyPage'

const NotFoundPage = () => {
  return (
    <EmptyPage
      title="Maaf, halaman yang kamu cari tidak ditemukan."
      message="Kamu bisa kembali ke aplikasi GGL."
      mt="50%"
      noMenu
      redirectHome
    />
  )
}

export default NotFoundPage
