import React from 'react'
import Container from '../containers/Container'
import { Image, Stack, Text } from '@chakra-ui/react'
import BtnPrimary from '../buttons/BtnPrimary'
import { useRouter } from 'next/router'

const EmptyPage = ({ name = '', title, message, noMenu = false, redirectHome = false, ...props }) => {
  const router = useRouter()
  return (
    <Container noMenu={noMenu} {...props}>
      <Stack alignItems="center" justifyContent="center" mt="32px" spacing={4}>
        <Image src="/images/empty-page.png" alt="empty" />
        <Text fontWeight="bold" fontSize="14px" color="#002C3F" textAlign="center">
          {title ? title : `Maaf, fitur ${name} belum tersedia saat ini.`}
        </Text>
        <Text fontSize="12px" textAlign="center">
          {message ? message : 'Kamu bisa coba akses GGL versi mobile untuk menggunakan fitur ini.'}
        </Text>
        <BtnPrimary label="Kembali" w={120} onClick={redirectHome ? () => router.replace('/') : () => router.back()} />
      </Stack>
    </Container>
  )
}

export default EmptyPage
